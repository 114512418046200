@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

body {
    background-color: #233237;
}
#LawFirmName {
    padding-left: 10px;
    font-family: sans-serif;
	font-style: italic;
    font-weight: 530;
    }

 #AttorneyLabel {
    padding-left: 50px;
    font-family: sans-serif;
    font-style: italic;
    font-weight: 400;
    }
.title-bar {
        margin-top: 3px;
        border: 1px darkgray;
        padding: 10px;
        box-shadow: 5px 10px 18px #888888;
        background-color: #062F4F;
    }

.offCanvasLeft {
    font-family: sans-serif;
    font-style: italic;
    font-weight: 400;
    background-color: navy;
    }

#mobile_city {
    padding: 10px;
    box-shadow: 5px 10px 8px #888888;
}
#criminal_front {
    float: right;
    padding: 10px;
    box-shadow: 5px 10px 8px #888888;
}
#get_started {
    float: right;
    padding: 10px;
    box-shadow: 5px 10px 8px #888888;
    }
#disclaimer {
    float: right;
    }
.div_clear {
    clear: left;
}
.grid-container {
    background-color: aliceblue;
}



.divorceIntakeAlertBottom{
  text-align: center;
  font-weight: bold;
  padding:10px;
  background: #420d09;
  color:#fff;
  margin-bottom:10px;
  display:none;
}

.toggle-check-input {
  width: 1px;
  height: 1px;
  position: absolute;
}

.toggle-check-text {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  background: #CCC;
  padding: 0.25em 0.5em 0.25em 2.5em;
  border-radius: 0em;
  min-width: 3em;
  color: #3C3C3E;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.15s;
}

.toggle-check-text:after {
  content: ' ';
  display: block;
  background: #FFF;
  width: 1.2em;
  height: 1.7em;
  border-radius: 0em;
  position: absolute;
  left: 0.3em;
  top: 0.25em;
  transition: left 0.15s, margin-left 0.15s;
}

.toggle-check-text:before {
  content: 'No';
}

.toggle-check-input:checked ~ .toggle-check-text {
  background: #009933;
  color: #FFF;
  padding-left: 0.5em;
  padding-right: 2em;
}

.toggle-check-input:checked ~ .toggle-check-text:before {
  content: 'Yes';
}

.toggle-check-input:checked ~ .toggle-check-text:after {
  left: 100%;
  margin-left: -1.4em;
}

.border2 {
    border: 3px solid white;

}

#saveButton {
    margin-left: 90%;
}

#shortContactFormSubmit {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding:10px;
  background: #FEFBEA;
  padding-top: 7%;  
  margin-top:50px;
  height: 200px; 
  box-shadow: 0 8px 6px -6px black;
  
}

.divorceIntakeAlertBottom {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding:10px;
  background: #FEFBEA;
  padding-top: 7%;  
  height: 200px; 
  box-shadow: 0 8px 6px -6px black;
  color: black;

}

#quoteFormSubmitAlert {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding:10px;
  background: #FEFBEA;
  padding-top: 7%;  
  height: 200px; 
  box-shadow: 0 8px 6px -6px black;
}

.lert {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding:10px;
  background: #FEFBEA;
  padding-top: 7%;  
  margin-top:600px;
  height: 200px; 
  box-shadow: 0 8px 6px -6px black;

}
